<template>
  <mobile-screen
    :header="true"
    screen-class="resources-availability gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-add-availibility'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "add-resource-availability",
              "add-resource-availability"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      @submit="submitForm"
      ref="addResourceAvailabilityForm"
      v-slot="{ errors }"
      class="form change-password-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{
            'has-error': errors.name
          }"
        >
          <span class="error-message" v-if="errors.name">
            {{
              displayLabelName(
                "resources",
                "add-resource-availability",
                "season-name-is-required"
              )
            }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="availabilityName"
                >{{
                  displayLabelName(
                    "resources",
                    "add-resource-availability",
                    "name"
                  )
                }}
                <span>*</span></label
              >
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                id="availabilityName"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          :class="{
            'has-error': errors.date_from || errors.date_to
          }"
        >
          <span class="error-message" v-if="errors.date_from || errors.date_to">
            {{ dateDisplayError(errors) }}
          </span>
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button
                  class="clebex-item-range-cta"
                  @click.prevent="openDateTimePicker('date', 'from')"
                >
                  {{ displayDate(selectedDateFromParsed) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button
                  @click.prevent="openDateTimePicker('date', 'to')"
                  class="clebex-item-range-cta"
                >
                  {{ displayDate(selectedDateToParsed) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{
            'has-error': errors.time_from || errors.time_to
          }"
        >
          <span class="error-message" v-if="errors.time_from || errors.time_to">
            {{ timeDisplayError(errors) }}
          </span>
          <div class="clebex-item-content-wrapper">
            <ul class="clebex-item-content-range">
              <li class="clebex-item-range from">
                <button
                  class="clebex-item-range-cta"
                  @click.prevent="openDateTimePicker('time-slot', 'from')"
                >
                  {{ displayTime(selectedHourFrom) }}
                </button>
              </li>
              <li class="clebex-item-range separator">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </li>
              <li class="clebex-item-range to">
                <button
                  @click.prevent="openDateTimePicker('time-slot', 'to')"
                  class="clebex-item-range-cta"
                >
                  {{ displayTime(selectedHourTo) }}
                </button>
              </li>
            </ul>
          </div>
        </li>
        <li
          class="clebex-item-section-item overflow-hidden"
          v-if="showDateFrom || showDateTo"
        >
          <div class="clebex-item-content-wrapper">
            <pill-menu-tabs
              :nav-items="pillTabs"
              :preselected-tab="preselectedTab"
              :change-tab-fn="changeTab"
            >
              <template v-slot:date>
                <select-date-range
                  :selectDateFn="selectDate"
                  :show-date-picker="showDateFrom || showDateTo"
                  :dates-to-watch="[selectedDateFrom, selectedDateTo]"
                  :selected-dates="[selectedDateFrom, selectedDateTo]"
                  :cyclic-year="true"
                  :show-year="false"
                />
              </template>
              <template v-slot:time-slot>
                <select-time-picker-range
                  :select-hour-fn="selectHour"
                  :selected-hours="[selectedHourFrom, selectedHourTo]"
                />
              </template>
            </pill-menu-tabs>
          </div>
        </li>
      </ul>
      <Field
        name="date_from"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateFrom"
        :disabled="submitting"
      />
      <Field
        name="date_to"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedDateTo"
        :disabled="submitting"
      />
      <Field
        name="time_from"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedHourFrom"
        :disabled="submitting"
      />
      <Field
        name="time_to"
        as="input"
        type="hidden"
        rules="required"
        v-model="selectedHourTo"
        :disabled="submitting"
      />

      <ul class="clebex-item-section">
        <li class="clebex-item-section-label-inner">
          <div class="clebex-label-content-wrapper">
            {{
              displayLabelName(
                "resources",
                "add-resource-availability",
                "on-the-days-of-the-week"
              )
            }}
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{
            'has-error': errors.one_checkbox_selected
          }"
        >
          <span class="error-message" v-if="errors.one_checkbox_selected">
            {{
              displayLabelName(
                "resources",
                "add-resource-availability",
                "days-of-the-week-are-required"
              )
            }}
          </span>
          <ul class="checkbox-day-list">
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-mo"
                  name="mo"
                  v-model="days.mo"
                />
                <label for="day-mo">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "mo"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-tu"
                  name="tu"
                  v-model="days.tu"
                />
                <label for="day-tu">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "tu"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-we"
                  name="we"
                  v-model="days.we"
                />
                <label for="day-we">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "we"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-th"
                  name="th"
                  v-model="days.th"
                />
                <label for="day-th">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "th"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-fr"
                  name="fr"
                  v-model="days.fr"
                />
                <label for="day-fr">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "fr"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-sa"
                  name="sa"
                  v-model="days.sa"
                />
                <label for="day-sa">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "sa"
                    )
                  }}.</label
                >
              </div>
            </li>
            <li class="checkbox-wrapper">
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  id="day-su"
                  name="su"
                  v-model="days.su"
                />
                <label for="day-su">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "resources",
                      "add-resource-availability",
                      "su"
                    )
                  }}.</label
                >
              </div>
            </li>
          </ul>
          <Field
            v-if="!isWeekDaySelected"
            name="one_checkbox_selected"
            as="input"
            type="hidden"
            rules="required"
            v-model="isWeekDaySelected"
            :disabled="submitting"
          />
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { mapActions, mapGetters, mapState } from "vuex";
import dayjs from "dayjs";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "AddResourceAvailability",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SelectTimePickerRange: defineAsyncComponent(() =>
      import("@/components/global/SelectTimePickerRange")
    ),
    PillMenuTabs: defineAsyncComponent(() =>
      import("@/components/global/PillMenuTabs")
    )
  },
  data() {
    return {
      formKey: 0,
      submitting: false,
      selectedHourFrom: null,
      selectedHourTo: null,
      timeFromVisible: false,
      timeToVisible: false,
      showDateFrom: false,
      showDateTo: false,
      selectedDateFrom: false,
      selectedDateTo: false,
      selectedDateFromParsed: null,
      selectedDateToParsed: null,
      days: {
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: false,
        su: false
      },
      pillTabs: ["date", "time-slot"],
      preselectedTab: null,
      helpSlug: "resources-availability-seasons"
    };
  },
  computed: {
    ...mapState("resource", ["resource"]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    isWeekDaySelected: {
      get() {
        const { mo, tu, we, th, fr, sa, su } = this.days;
        return mo || tu || we || th || fr || sa || su;
      },
      set() {
        this.days = {
          mo: false,
          tu: false,
          we: false,
          th: false,
          fr: false,
          sa: false,
          su: false
        };
      }
    }
  },
  methods: {
    ...mapActions("resourceAvailability", ["getAvailabilities"]),
    submitForm(values) {
      if (values) {
        // TODO temporary fix, remove when calendar reworked
        values.date_from = DateTime.fromFormat(
          values.date_from,
          "yyyy-dd-MM"
        ).toFormat("MM-dd");
        values.date_to = DateTime.fromFormat(
          values.date_to,
          "yyyy-dd-MM"
        ).toFormat("MM-dd");
        this.$store.commit("loader/setScreenLoading", true, { root: true });
        const data = {
          ...values,
          mo: Number(this.days.mo),
          tu: Number(this.days.tu),
          we: Number(this.days.we),
          th: Number(this.days.th),
          fr: Number(this.days.fr),
          sa: Number(this.days.sa),
          su: Number(this.days.su)
        };

        httpServiceAuth
          .post(
            `${apiEndpoints.company.resources}/${this.resource.data.id}/resourceavailabilities`,
            data
          )
          .then(() => {
            this.getAvailabilities(this.resource.data.id);
            this.clearValues();
            if (this.formKey === 10) {
              this.formKey = 0;
            } else {
              this.formKey++;
            }
          })
          .catch(error => {
            if (error.response) {
              errorHandler(error.response, this.findElement());
            }
          })
          .finally(() => {
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateFromParsed = dates[0].displayDate;
      } else {
        this.selectedDateFrom = null;
        this.selectedDateFromParsed = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
        this.selectedDateToParsed = dates[1].displayDate;
      } else {
        this.selectedDateTo = null;
        this.selectedDateToParsed = null;
      }
    },
    selectHour(hours) {
      this.selectedHourFrom = hours[0];
      this.selectedHourTo = hours[1];
    },
    clearValues() {
      this.selectedDateFromParsed = null;
      this.selectedDateFrom = false;
      this.selectedDateToParsed = null;
      this.selectedDateTo = false;
      this.selectedHourFrom = null;
      this.selectedHourTo = null;
      this.days = {
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: false,
        su: false
      };
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }

      return formatDate(date, "D MMMM", true);
    },
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "select-time");
      }
      const parsedTime = dayjs(
        time,
        this.globalTimeFormat.replace(" A", "")
      ).toDate();
      return formatDate(parsedTime.toString(), this.globalTimeFormat);
    },
    changeTab() {},
    dateDisplayError(errors) {
      if (errors.date_from) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "date-from-is-required"
        );
      }

      if (errors.date_to) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "date-to-is-required"
        );
      }
    },
    timeDisplayError(errors) {
      if (errors.time_from) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "time-from-is-required"
        );
      }

      if (errors.time_to) {
        return this.displayLabelName(
          "resources",
          "add-resource-availability",
          "time-to-is-required"
        );
      }
    },
    openDateTimePicker(tab, fromTo) {
      if (this.preselectedTab !== tab) {
        this.preselectedTab = tab;
        if (fromTo === "from" && this.showDateTo) {
          this.showDateFrom = true;
          this.showDateTo = false;
        } else {
          this.showDateTo = true;
          this.showDateFrom = false;
        }
      } else {
        if (fromTo === "from") {
          this.showDateFrom = !this.showDateFrom;
        } else {
          this.showDateTo = !this.showDateTo;
        }
      }
    },
    reverseDateMonth(date) {
      const reversedDate = date.split("-");
      return `${reversedDate[1]}-${reversedDate[0]}`;
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
